import './App.css';

import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, message, TreeSelect } from 'antd';
import { useEffect, useState } from 'react';

import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getCountryCodes } from './country';

function App() {

  const [searchParams] = useSearchParams();
  const [data, setData] = useState(null);

  const [view, setView] = useState('form');
  async function getIP() {
    const response = await axios.get('https://api.ipify.org/?format=json');
    return response.data?.ip || '0.0.0.0';
  }

  const path = window.location.pathname;
  const reqData = {
    accountID: searchParams.get('accountID'),
    campId: searchParams.get('campId'),
    supplier: searchParams.get('supplier'),
    slug: path.split('/')[path.split('/').length - 1]
  }
  const getFormData = () => {
    axios
      .get(`https://stageapi.convey.ai/convey_ai/public/forms/${reqData.accountID}/${reqData.slug}/${reqData.campId}/${reqData.supplier}`)
      .then(responseData => {
        setData(responseData.data);
      });
  }
  useEffect(() => {
    getIP();
    getFormData();
  }, []);

  const [form] = Form.useForm();
  const onFinish = async values => {
    values['campId'] = searchParams.get('campId');
    values['supplierId'] = searchParams.get('supplier');
    values['accountId'] = searchParams.get('accountID');
    values['ip_address'] = await getIP();
    axios
      .post('https://stageapi.convey.ai/convey_ai/public/lead/generateLead', values)
      .then(res => {
        setView('thankyou');
      }, error => {
        message.error("Unable to submit the form please try again later, reason: " + error?.response?.data?.message);
      });
  };

  return (<>
    {
      view === 'form' &&
      <div className={`h-100`}>
        <Row justify="center" className="align-items-stretch h-100">
          <Col xs={23} sm={23} md={23} lg={23}>
            <div className="container d-flex flex-column justify-content-center h-100">
              <Row justify="center">
                <div>
                  <style dangerouslySetInnerHTML={{ __html: data?.css }} />
                  {data && (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      style={{ top: '100px' }}
                    >
                      <h1>{data.formName}</h1>

                      <div className="mt-12">
                        <RenderForm onFinish={onFinish} fields={data.fields} />
                      </div>
                    </Col>
                  )}

                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    }
    {
      view === 'thankyou' && <>

        <div dangerouslySetInnerHTML={{ __html: data?.thanksPage }}>

        </div ></>
    }
  </>
  );

}



const RenderForm = porps => {
  const { fields, onFinish } = porps;
  console.log(fields);
  const onFinishFailed = (data) => {
    console.log(data)
  }

  return (
    <>
      {
        fields.length > 0 &&
        <Form
          name='dynamicUserSelectedForm'
          layout='vertical'
          onFinishFailed={onFinishFailed}
          onFinish={onFinish}>
          {
            fields?.map(value => {
              return (
                <Form.Item key={uuidv4()} label={value.label} name={value.fieldName}
                  rules={[{ 'required': value.required === 'required' ? true : false, 'message': value.requiredMessage }, { type: value.fieldType, message: 'Invalid format' }]}>
                  {value.fieldType === 'text' && (
                    <Input placeholder={value.placeHolder} className={value?.css} />
                  )}
                  {value.fieldType === 'email' && (
                    <Input placeholder={value.placeHolder} className={value?.css} />
                  )}
                  {value.fieldType === 'number' && (
                    <InputNumber style={{ width: '100%' }} placeholder={value.placeHolder} className={value?.css} />
                  )}
                  {value.fieldType === 'date' && (
                    <DatePicker placeholder={value.placeHolder} className={value?.css} />
                  )}
                  {value.fieldType === 'url' && (
                    <Input placeholder={value.placeHolder} className={value?.css} />
                  )}
                  {value.fieldType === 'multi line' && (
                    <Input.TextArea placeholder={value.placeHolder} className={value?.css} />
                  )}
                  {value.fieldType && value.fieldType === 'select' && (
                    <Select>
                      {value?.allowedFields?.map(e => <Select.Option value={e} key={e}>{e}</Select.Option>)}
                    </Select>
                  )}
                  {value.fieldType === 'Phone number' && (
                    <Space.Compact>
                      {' '}
                      <Select
                        showSearch
                        className={value?.css}
                        name="countryCode"
                        options={getCountryCodes()}
                      />
                      <InputNumber
                        style={{ width: '100%' }}
                        className={value?.css}
                        placeholder={value.placeHolder}
                      />
                    </Space.Compact>
                  )}
                  {value.fieldType && value.fieldType === 'treeselect' && (
                    <TreeSelect showSearch
                      style={{ width: '100%' }}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      placeholder={value.placeHolder}
                      allowClear
                      className={value.css}
                      treeData={value?.allowedValues || []} />
                  )}
                </Form.Item>
              );
            })}
          <Button type='primary' htmlType='submit' className='form-submit-button' block>Submit</Button>
        </Form >

      }
    </>
  )
};

export default App;
